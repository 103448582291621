import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/icons/close.svg'


const _hoisted_1 = {
  key: 0,
  class: "alert alert-warning"
}
const _hoisted_2 = {
  key: 1,
  class: "no-data"
}
const _hoisted_3 = {
  key: 0,
  class: "alert alert-warning"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "txt-total" }

import { IonButtons, IonFooter, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, modalController, IonIcon, onIonViewDidEnter } from '@ionic/vue';
import { cartOutline } from 'ionicons/icons';
import CartItem from '@/components/CartItem.vue';
import store from '@/store';
import { toPrice } from '@/pipes/price';
import router from '@/router';
import { trackView } from '@/services/AnalyticsService';

//todo: copy checkout success/ failed etc pages from old repo 


export default /*@__PURE__*/_defineComponent({
  __name: 'CartPage',
  setup(__props) {
 
onIonViewDidEnter(() => {
    trackView('cart-page');
})

function goToHome() {
   
   modalController.getTop().then(o => {
       if(o) {
           modalController.dismiss({
               page: "home"
           });
       } else {
           router.push("/home");
       }
   }); 
}

function goToCheckout() {
   
    modalController.getTop().then(o => {
        if(o) {
            modalController.dismiss({
                page: "confirm"
            });
        } else {
            router.push("/confirm");
        }
    }); 
}

function dismiss() {
    modalController.getTop().then(o => {
        if(o) {
            modalController.dismiss();
        //} else if(window.history.length > 0) {
        //    router.back();
        } else {
            router.push('/');
        }
    });
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonHeader), { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonToolbar), null, {
            default: _withCtx(() => [
              _createVNode(_unref(IonButtons), { slot: "start" }, {
                default: _withCtx(() => [
                  _createElementVNode("button", {
                    type: "button",
                    color: "primary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (dismiss()))
                  }, _cache[4] || (_cache[4] = [
                    _createElementVNode("img", { src: _imports_0 }, null, -1)
                  ]))
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTitle), null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Your cart')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(IonContent), { fullscreen: true }, {
        default: _withCtx(() => [
          (!_unref(store).state.store.supportPickup && !_unref(store).state.store.supportDelivery)
            ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.$t('Delivery option not available for now! Please contact store owner.')), 1))
            : _createCommentVNode("", true),
          (_unref(store).state.cartLength == 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_unref(IonIcon), { icon: _unref(cartOutline) }, null, 8, ["icon"]),
                _createElementVNode("h5", null, _toDisplayString(_ctx.$t('Your cart is empty')), 1),
                _createElementVNode("p", null, _toDisplayString(_ctx.$t('You can manage your shopping cart here')), 1),
                _createElementVNode("button", {
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (dismiss())),
                  class: "btn"
                }, _toDisplayString(_ctx.$t('Start Shopping')), 1)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.cart, (item, index) => {
            return (_openBlock(), _createBlock(CartItem, {
              item: item,
              cart_item_id: index,
              key: index
            }, null, 8, ["item", "cart_item_id"]))
          }), 128))
        ]),
        _: 1
      }),
      (_unref(store).state.cartLength > 0 && (_unref(store).state.store.supportPickup || _unref(store).state.store.supportDelivery))
        ? (_openBlock(), _createBlock(_unref(IonFooter), { key: 0 }, {
            default: _withCtx(() => [
              (!_unref(store).state.store.supportPickup 
                && _unref(store).state.store.supportDelivery && !_unref(store).getters.isStoreOpen)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_unref(store).getters.storeReopenAt)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(_ctx.$t('Reopen at {time}', { time: _unref(store).getters.storeReopenAt })), 1)
                        ], 64))
                      : _createCommentVNode("", true),
                    (!_unref(store).getters.storeReopenAt)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(_ctx.$t('Close now')), 1)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_unref(IonToolbar), null, {
                default: _withCtx(() => [
                  _createElementVNode("button", {
                    class: "add-to-cart",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (goToCheckout())),
                    disabled: !_unref(store).state.store.supportPickup && !_unref(store).getters.isStoreOpen
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('Checkout')) + " ", 1),
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "sep" }, "·", -1)),
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_unref(toPrice)(_unref(store).state.cartTotal)), 1)
                  ], 8, _hoisted_4),
                  _createElementVNode("button", {
                    class: "btn-shopping",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (goToHome()))
                  }, _toDisplayString(_ctx.$t('Continue Shopping')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})
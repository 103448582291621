import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"
import _imports_0 from './assets/icons/close.svg'
import _imports_1 from './assets/icons/store.svg'
import _imports_2 from './assets/icons/chevron-down.svg'
import _imports_3 from './assets/icons/home-black.svg'
import _imports_4 from './assets/icons/profile.svg'
import _imports_5 from './assets/icons/order.svg'
import _imports_6 from './assets/icons/address.svg'
import _imports_7 from './assets/icons/instagram.svg'
import _imports_8 from './assets/icons/facebook.png'
import _imports_9 from './assets/icons/twitter.png'
import _imports_10 from './assets/icons/youtube.svg'


const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "store-logo",
  src: _imports_1
}
const _hoisted_3 = { id: "currency-menu-trigger" }
const _hoisted_4 = ["router-link"]
const _hoisted_5 = { class: "hello-detail" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "track-wrapper" }
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = {
  key: 0,
  class: "social-wrapper"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }
const _hoisted_15 = { key: 3 }
const _hoisted_16 = { key: 4 }
const _hoisted_17 = { key: 5 }
const _hoisted_18 = { key: 6 }
const _hoisted_19 = { key: 7 }

import { checkmark, logOutOutline, logoSnapchat, logoWhatsapp, mailOutline, openOutline } from 'ionicons/icons';
import {
  IonApp,
  IonContent,
  IonItem,
  IonHeader,
  IonFooter,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
  menuController,
  IonIcon,
  IonPopover,
  IonButton,
  popoverController
} from '@ionic/vue';

import CopyRights from '@/components/CopyRights.vue';

import { onErrorCaptured, ref } from 'vue';
import store from './store';
import router from './router';
import LanguageList from './components/LanguageList.vue';
import UpdateAlert from './components/UpdateAlert.vue';
//import { useI18n } from 'vue-i18n-composable/src/index'

//import { useStore } from 'vuex';



export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const order_uuid = ref();

function logout() {
  store.commit('logout');

  router.push('/');
}

function showOrderPage() {
  if (order_uuid.value) {
    router.push('/order/' + order_uuid.value);
    order_uuid.value = null;
    menuController.close();
  }
}

async function showLanguages(event) {
  const popover = await popoverController.create({
      component: LanguageList, 
      event: event,
  });
  await popover.present();
}

function setCurrency(currency) {
  store.commit('setCurrencyPref', currency);

  popoverController.dismiss();
}

onErrorCaptured((event) => { 
    
   const chunkCssFailedMessage = /Loading CSS chunk [\d]+ failed/;
   const chunkJSFailedMessage = /Loading chunk [\d]+ failed/;
 
   if (chunkJSFailedMessage.test(event.message) || chunkCssFailedMessage.test(event.message)) {
    store.state.appUpdateAvailable = true;// need to refresh page 
     //return window.location.reload();
   }
});

 
/*onMounted(() => {
  store.dispatch('getStoreDetail').then(() => {
  });
})

/*
const path = window.location.pathname.split('/')[1];
if (path !== undefined) {
 selectedIndex.value = appPages.findIndex((page) => page.url.split('/')[1] === path);
}

login with otp 
if (cookieService.get('otp')) {
               _platform.ready().then(_ => {
                   setTimeout(() => {
                       loginByOtp(cookieService.get('otp'));
                   }, 800); // to fix: https://www.pivotaltracker.com/story/show/168368025
               });
           }


const store = useStore();

store.dispatch('getStoreDetail').then(() => {
})*/


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonApp), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonSplitPane), { "content-id": "main-content" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonMenu), {
            "content-id": "main-content",
            disabled: !_unref(store).state.store,
            type: "overlay"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonHeader), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonToolbar), null, {
                    default: _withCtx(() => [
                      (_unref(menuController).isOpen)
                        ? (_openBlock(), _createBlock(_unref(IonButtons), {
                            key: 0,
                            slot: "start"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonMenuToggle), null, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(IonButton), null, {
                                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                                      _createElementVNode("img", { src: _imports_0 }, null, -1)
                                    ])),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_unref(store).state.store)
                        ? (_openBlock(), _createBlock(_unref(IonTitle), {
                            key: 1,
                            class: "sidebar-title"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_router_link, { to: "/home" }, {
                                default: _withCtx(() => [
                                  (_unref(store).state.store.logo)
                                    ? (_openBlock(), _createElementBlock("img", {
                                        key: 0,
                                        class: "store-logo",
                                        src: 'https://res.cloudinary.com/plugn/image/upload/c_scale,f_jpg,q_100,w_128/restaurants/'
                  + _unref(store).state.store.restaurant_uuid + '/logo/' + _unref(store).state.store.logo
                                      }, null, 8, _hoisted_1))
                                    : _createCommentVNode("", true),
                                  (!_unref(store).state.store.logo)
                                    ? (_openBlock(), _createElementBlock("img", _hoisted_2))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_unref(IonButtons), { slot: "end" }, {
                        default: _withCtx(() => [
                          _createElementVNode("button", {
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (showLanguages($event)))
                          }, [
                            _createTextVNode(_toDisplayString(_unref(store).state.language? _unref(store).state.language.code : "EN") + " ", 1),
                            _cache[6] || (_cache[6] = _createElementVNode("img", { src: _imports_2 }, null, -1))
                          ]),
                          _createElementVNode("button", _hoisted_3, [
                            _createTextVNode(_toDisplayString(_unref(store).state.currency_pref) + " ", 1),
                            _cache[7] || (_cache[7] = _createElementVNode("img", { src: _imports_2 }, null, -1))
                          ]),
                          _createVNode(_unref(IonPopover), {
                            trigger: "currency-menu-trigger",
                            "trigger-action": "click"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.store.currencies, (currency, index) => {
                                return (_openBlock(), _createBlock(_unref(IonItem), {
                                  tappable: "",
                                  onClick: ($event: any) => (setCurrency(currency)),
                                  key: index
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(IonLabel), null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("h5", null, _toDisplayString(currency.code), 1),
                                        _createElementVNode("p", null, _toDisplayString(currency.title), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    (_unref(store).state.currency_pref == currency.code)
                                      ? (_openBlock(), _createBlock(_unref(IonIcon), {
                                          key: 0,
                                          icon: _unref(checkmark)
                                        }, null, 8, ["icon"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"]))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(IonContent), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonList), { id: "menu-item-list" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: "hello-wrapper",
                        "router-link": _unref(store).state.isLogin ? '/profile' : '/email-login'
                      }, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('Hello')) + ",", 1),
                          (_unref(store).state.isLogin)
                            ? (_openBlock(), _createElementBlock("h5", _hoisted_6, _toDisplayString(_unref(store).state.user.name), 1))
                            : _createCommentVNode("", true),
                          (!_unref(store).state.isLogin)
                            ? (_openBlock(), _createElementBlock("h5", _hoisted_7, _toDisplayString(_ctx.$t('Guest')), 1))
                            : _createCommentVNode("", true)
                        ]),
                        (!_unref(store).state.isLogin)
                          ? (_openBlock(), _createElementBlock("button", {
                              key: 0,
                              onClick: _cache[1] || (_cache[1] = ($event: any) => {_unref(router).push('/email-login'); _unref(menuController).close()})
                            }, _toDisplayString(_ctx.$t("Login Now")), 1))
                          : _createCommentVNode("", true),
                        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "clearfix" }, null, -1))
                      ], 8, _hoisted_4),
                      _createVNode(_unref(IonMenuToggle), { "auto-hide": false }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonItem), {
                            tappable: "",
                            "router-direction": "root",
                            "router-link": "/home",
                            lines: "none"
                          }, {
                            default: _withCtx(() => [
                              _cache[9] || (_cache[9] = _createElementVNode("img", {
                                "aria-hidden": "true",
                                src: _imports_3
                              }, null, -1)),
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('Home')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_unref(store).state.isLogin)
                        ? (_openBlock(), _createBlock(_unref(IonMenuToggle), {
                            key: 0,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonItem), {
                                tappable: "",
                                "router-direction": "root",
                                "router-link": "/profile",
                                lines: "none"
                              }, {
                                default: _withCtx(() => [
                                  _cache[10] || (_cache[10] = _createElementVNode("img", {
                                    "aria-hidden": "true",
                                    src: _imports_4
                                  }, null, -1)),
                                  _createVNode(_unref(IonLabel), null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('Profile')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_unref(store).state.isLogin)
                        ? (_openBlock(), _createBlock(_unref(IonMenuToggle), {
                            key: 1,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonItem), {
                                tappable: "",
                                "router-direction": "root",
                                "router-link": "/orders",
                                lines: "none"
                              }, {
                                default: _withCtx(() => [
                                  _cache[11] || (_cache[11] = _createElementVNode("img", {
                                    "aria-hidden": "true",
                                    src: _imports_5
                                  }, null, -1)),
                                  _createVNode(_unref(IonLabel), null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('Orders')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      (_unref(store).state.isLogin)
                        ? (_openBlock(), _createBlock(_unref(IonMenuToggle), {
                            key: 2,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonItem), {
                                tappable: "",
                                "router-direction": "root",
                                "router-link": "/addresses",
                                lines: "none"
                              }, {
                                default: _withCtx(() => [
                                  _cache[12] || (_cache[12] = _createElementVNode("img", {
                                    "aria-hidden": "true",
                                    src: _imports_6
                                  }, null, -1)),
                                  _createVNode(_unref(IonLabel), null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('Addresses')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_unref(IonMenuToggle), { "auto-hide": false }, {
                        default: _withCtx(() => [
                          _createVNode(_unref(IonItem), {
                            tappable: "",
                            "router-direction": "root",
                            "router-link": "/store-info",
                            lines: "none"
                          }, {
                            default: _withCtx(() => [
                              _cache[13] || (_cache[13] = _createElementVNode("img", {
                                "aria-hidden": "true",
                                src: _imports_1
                              }, null, -1)),
                              _createVNode(_unref(IonLabel), null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('Store Info')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      (_unref(store).state.isLogin)
                        ? (_openBlock(), _createBlock(_unref(IonMenuToggle), {
                            key: 3,
                            "auto-hide": false
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_unref(IonItem), {
                                tappable: "",
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (logout()))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(IonIcon), { icon: _unref(logOutOutline) }, null, 8, ["icon"]),
                                  _createVNode(_unref(IonLabel), null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('Log Out')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("h5", null, _toDisplayString(_ctx.$t('Track your order')), 1),
                    _createElementVNode("form", null, [
                      _withDirectives(_createElementVNode("input", {
                        placeholder: _ctx.$t("Order Code"),
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((order_uuid).value = $event))
                      }, null, 8, _hoisted_9), [
                        [_vModelText, order_uuid.value]
                      ]),
                      _createElementVNode("button", {
                        onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (showOrderPage()), ["prevent"]))
                      }, _toDisplayString(_ctx.$t('Track')), 1)
                    ])
                  ]),
                  (_unref(store).state.store)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.store.webLinks, (link, index) => {
                          return (_openBlock(), _createElementBlock("a", {
                            target: "_blank",
                            href: _unref(store).getters.weblink(link),
                            key: index
                          }, [
                            (link.web_link_type == 1)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                  _createVNode(_unref(IonIcon), {
                                    slot: "start",
                                    icon: _unref(openOutline)
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('Weblink')), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (link.web_link_type == 5)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_13, [
                                  _createVNode(_unref(IonIcon), {
                                    slot: "start",
                                    icon: _unref(logoSnapchat)
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('Snapchat')), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (link.web_link_type == 6)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                                  _createVNode(_unref(IonIcon), {
                                    slot: "start",
                                    icon: _unref(logoWhatsapp)
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('WhatsApp')), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (link.web_link_type == 7)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_15, [
                                  _createVNode(_unref(IonIcon), {
                                    slot: "start",
                                    icon: _unref(mailOutline)
                                  }, null, 8, ["icon"]),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('eMail')), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (link.web_link_type == 3)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                                  _cache[14] || (_cache[14] = _createElementVNode("img", { src: _imports_7 }, null, -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('Instagram')), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (link.web_link_type == 2)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_17, [
                                  _cache[15] || (_cache[15] = _createElementVNode("img", { src: _imports_8 }, null, -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('Facebook')), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (link.web_link_type == 4)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_18, [
                                  _cache[16] || (_cache[16] = _createElementVNode("img", { src: _imports_9 }, null, -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('Twitter')), 1)
                                ]))
                              : _createCommentVNode("", true),
                            (link.web_link_type == 8)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_19, [
                                  _cache[17] || (_cache[17] = _createElementVNode("img", { src: _imports_10 }, null, -1)),
                                  _createTextVNode(" " + _toDisplayString(_ctx.$t('YouTube')), 1)
                                ]))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_11))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_unref(IonFooter), null, {
                default: _withCtx(() => [
                  _createVNode(CopyRights)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["disabled"]),
          _createVNode(_unref(IonRouterOutlet), { id: "main-content" })
        ]),
        _: 1
      }),
      (_unref(store).state.appUpdateAvailable)
        ? (_openBlock(), _createBlock(UpdateAlert, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})